module.exports = [{
      plugin: require('/home/jh/projects/live-on-stage-360/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"ynq5cmy"}},
    },{
      plugin: require('/home/jh/projects/live-on-stage-360/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149427937-1"},
    },{
      plugin: require('/home/jh/projects/live-on-stage-360/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
